$REACT_APP_CUSTOM_ENABLED: true;
/**
 * Loading component
 */
.Loading {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  &.absolute {
    position: absolute;
    z-index: 99;
  }
}
